.about {
  padding: rem(50px 66px);
  display: flex;
  justify-content: center;
  align-items: center;
  gap: rem(300px);
  position: relative;
  z-index: 3;
  @include media-breakpoint-down(lg) {
    flex-direction: column;
    gap:15rem;
  }
  @include media-breakpoint-down(xs) {
    gap:12rem;
    padding: 7rem 1rem;
  }
  &__item {
    font-size: rem(24px);
    position: relative;
    //transition: all .2s linear;
    &:hover {
      @include media-breakpoint-up(xl) {
        h2, p {
          color:cl(white);
        }
        .about__more {
          color:cl(white);
          border-color:cl(white);
        }
        .about__back {
          opacity: 1;
        }
      }
    }
    @include media-breakpoint-down(lg) {
      h2, p {
        color:cl(white);
      }
      .about__more {
        color:cl(white);
        border-color:cl(white);
      }
    }
    h2 {
      text-transform: uppercase;
      font-family: $fontTitle;
      font-size: rem(40px);
      margin: rem(0 0 20px);
      @include media-breakpoint-up(xl) {
        color:cl(primary);
      }
      @include media-breakpoint-down(xs) {
        margin: rem(0 0 10px);
      }
    }
    p {
      margin: 0;
      @include media-breakpoint-up(xl) {
        color:#221d1e;
      }
    }
    * {
      transition: all .2s linear;
    }
  }
  &__back {
    @extend .pos-center;
    width:rem(480px);
    height: rem(480px);
    border-radius: 50%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    box-shadow:rem(-16px 0 0 cl(secondary) , -10px 25px 60px cl(black, .34));
    opacity: 0;
    @include media-breakpoint-down(lg) {
      opacity: 1;
    }
    @include media-breakpoint-down(xs) {
      width: 23rem;
      height: 23rem;
    }
    &:before {
      content: "";
      @extend .full;
      z-index: 1;
      border-radius: 50%;
      background: rgba(59, 85, 199, .6);
    }
  }
  &__inner {
    position: relative;
    z-index: 2;
  }
  &__num {
    display: block;
    color:#ededed;
    font-size: rem(40px);
    margin: rem(0 0 45px);
    @include media-breakpoint-down(xs) {
      margin: 0 0 1rem;
      font-size: rem(30px);
    }
  }
  &__more {
    display: inline-block;
    border:1px solid #9f9c9e;
    line-height: rem(42px);
    border-radius: rem(22px);
    padding: rem(0 20px);
    color:#423c3f;
    font-size: rem(15px);
    margin: rem(30px 0 0);
    &:hover {
      text-decoration: none;
      border-color: cl(white);
      color:cl(white);
      background: cl(white, .1);
    }
    @include media-breakpoint-down(xs) {
      margin: rem(25px 0 0);
    }
    i {
      transition: all .1s linear;
    }
  }
}