.branding {
    &__item {
      display: flex;
      align-items: flex-start;
      gap:rem(40px);
      border-radius: rem(20px 0);
      overflow: hidden;
      background: cl(white);
      box-shadow:rem(0 5px 20px cl(black, .24));
      padding: rem(20px);
      margin: rem(0 0 20px);
      @include media-breakpoint-down(xs) {
        flex-direction: column;
        align-items: center;
        justify-content: center;
      }
    }
    &__image {
      width:rem(200px);
      height: auto;
      flex-shrink: 0;
    }
  }