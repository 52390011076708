.staff {
  &__title {
    position: relative;
    text-transform: uppercase;
    margin: rem(30px 0 40px);
    .decor {
      top: rem(-30px);
      @include media-breakpoint-up(sm) {
        left:rem(-150px);
      }
      @include media-breakpoint-down(xs) {
        right:rem(20px);
        top:-4.875rem;
      }
    }
    h2 {
      margin: rem(0 0 20px);
      font-family: $fontTitle;
      color:cl(primary);
      font-weight: normal;
      text-transform: uppercase;
      position: relative;
      z-index: 2;
    }
  }
  &__list {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    --gap-side: 3.125rem;
    gap:1.875rem var(--gap-side);
    list-style: none;
    margin: rem(0 0 50px);
    @include media-breakpoint-down(xs) {
      --gap-side: 1.875rem;
    }
    &--sm {
      .staff__item {
        @include media-breakpoint-up(xl) {
          --cols: 3;
        }
      }
    }
  }
  &__item {
    text-align: center;
    font-size: rem(16px);
    line-height: 1.2;
    --cols: 3;
    flex-basis: calc((100% - var(--gap-side) * (var(--cols) - 1)) / var(--cols));
    padding: 0!important;
    &:before {
      opacity: 0;
    }
    @include media-breakpoint-down(lg) {
      //--cols: 2;
    }
    @include media-breakpoint-down(xs) {
      --cols: 2;
    }
    h3 {
      color:cl(primary);
      font-size: rem(20px);
      font-family: $fontTitle;
      margin: rem(0 0 5px);
      a {
        &:hover {
          text-decoration: none;
          color:cl(secondary);
        }
      }
    }
    p {
      margin: 0;
    }
  }
  &__photo {
    display: block;
    width: rem(170px);
    height: rem(170px);
    border-radius: 50%;
    overflow: hidden;
    margin: rem(0 auto 35px);
    box-shadow:rem(-10px 25px 60px cl(black, .34));
    &:hover {
      img {
      }
    }
    img {
      display: block;
      width:100%;
      height: 100%!important;
      margin: 0!important;
      max-width:100%!important;
      object-fit: cover;
      transition: all .2s linear;
    }
  }
}