.decor {
  position: absolute;
  &--top-right {
    span {
      &:nth-child(2) {
        top:rem(-5px);
        right:0;
      }
    }
  }
  &--bottom-left {
    span {
      &:nth-child(2) {
        bottom:rem(10px);
        left:rem(-11px);
      }
    }
  }
  span {
    display: block;
    &:nth-child(1) {
      @include circle(113px);
      background: linear-gradient(135deg, rgba(110,169,155,0.75) 0%, rgba(110,169,155,0.2) 50%, rgba(110,169,155,0) 80%);
    }
    &:nth-child(2) {
      position: absolute;
      @include circle(40px);
      background: linear-gradient(135deg, rgba(28,63,149,0.65) 0%,rgba(28,63,149,0.1) 100%);
      opacity: .7;
    }
  }
}