/* banner start */
.banner {
  position: relative;
  background: cl(primary);
  overflow: hidden;
  &--home {
    height: 100vh;
    @include media-breakpoint-down(lg) {
      //height: 60rem;
    }
    @include media-breakpoint-down(sm) {
      height: 31rem;
    }
    @include media-breakpoint-down(xs) {
      height: 40rem;
    }
  }
  &--page {
    height: rem(675px);
    background-position: 50% 50%;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    color:white;
    @include media-breakpoint-up(lg) {
      background-attachment: fixed;
    }
    @include media-breakpoint-down(xs) {
      height: 33rem;
    }
    &:before{
      content: "";
      @extend .full;
      background: cl(black, 0.1);
    }
    .container {
      position: relative;
      z-index: 2;
    }
    h1 {
      font-size: rem(80px);
      font-weight: normal;
      text-transform: uppercase;
      font-family: $fontTitle;
      margin: 0;
      @include media-breakpoint-down(xs) {
        font-size: rem(40px);
      }
    }
  }
  &__video {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    video {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
    }
    video[poster]{
      object-fit: cover;
    }
  }
  &__text {
    position: relative;
    width:100%;
    height: 100%;
    color:cl(white);
    z-index: 6;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    p {
      margin: 0;
    }
  }
  &__ttl {
    display: block;
    text-transform: uppercase;
    font-size: rem(60px);
    margin: rem(0 0 10px);
    font-weight: normal;
    @include media-breakpoint-down(xs) {
      font-size: rem(40px);
    }
  }
  &__txt {
    display: block;
    text-transform: uppercase;
    font-family: $fontTitle;
    font-size: rem(100px);
    margin: 0;
    @include media-breakpoint-down(xs) {
      font-size: rem(60px);
    }
  }
  &__waves {
    position:absolute;
    left:0;
    bottom: 0;
    width: 100%;
    height:rem(100px);
    margin-bottom:rem(-1px); /*Fix for safari gap*/
    z-index: 100;
    @include media-breakpoint-down(xs) {
      height:rem(80px);
    }
    svg,
    img{
      display: block;
      width:100%;
      height: 100%;
    }
  }
  &__leaf {
    position: absolute;
    width:rem(110px);
    height: auto;
    transform: rotate(70deg);
    bottom: 0;
    right:rem(65px);
    z-index: 101;
  }
}
/* banner end */

