.search {
  position: relative;
  @include media-breakpoint-up(xl) {
    z-index: 2;
  }
  &__show {
    width:rem(45px);
    height: rem(45px);
    border-radius: 50%;
    background: none;
    border:0;
    color:cl(white);
    font-size: rem(20px);
    &:before {
      content: '\f002';
      display: block;
      font-family: 'Font Awesome 5 Free';
      font-weight: 900;
    }
    &--active {
      background:cl(white);
      color:cl(secondary);
      &:before {
        content: '\f00d';
      }
    }
    &:hover {
      background:cl(white);
      color:cl(secondary);
    }
    &:focus {
      //outline: none;
      //background:cl(white);
      //color:cl(secondary);
    }
  }
  &__panel {
    display: none;
    position: absolute;
    right:calc(100% + .5rem);
    top:0;
    width:rem(300px);
    box-shadow:rem(-10px 25px 60px cl(black, .34));
    border-radius: rem(22px);
  }
  &__input {
    display: block;
    width:100%;
    font-family: $font;
    height: rem(45px);
    border-radius: rem(22px);
    background: cl(white);
    border:rem(1px solid cl(gray));
    padding:rem(0 50px 0 16px);
    font-size:rem(15px);
    color:cl(text);
    @include placeholder(cl(gray));
    &:focus {
      outline: none;
      box-shadow:none;
      background: cl(white);
      border-color:cl(secondary);
    }
  }
  &__button {
    position: absolute;
    right:rem(1px);
    top:rem(1px);
    width:rem(43px);
    height: rem(43px);
    background: none;
    border:0;
    border-radius: rem(22px);
    background:cl(secondary);
    color:cl(white);
    font-weight: 700;
  }
}