.sidebar {
  position: relative;
  z-index: 2;
  @include media-breakpoint-up(lg) {
    padding: rem(0 10px 0 0);
  }
  @include media-breakpoint-down(xs) {
    margin-bottom: rem(40px);
  }
  &__leaf {
    position: absolute;
    width:rem(110px);
    height: auto;
    transform: rotate(-70deg) scaleX(-1);
    bottom: rem(-85px);
    left: rem(-50px);
    @include media-breakpoint-down(xs) {
      display: none;
    }
  }
  &__open {
    width:100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: rem(18px);
    line-height: rem(59px);
    border-radius: rem(30px);
    background: cl(primary);
    color:cl(white);
    &:before {
      content: "Open Menu";
      display: block;
    }
    &--active {
      &:before {
        content: "Hide Menu";
        display: block;
      }
      span {
        transform: rotate(180deg);
      }
    }
    span {
      transition: all .2s linear;
    }
    @include media-breakpoint-up(sm) {
      display: none;
    }
  }
  &__menu {
    list-style: none;
    background: cl(white);
    border-radius: rem(25px 0);
    overflow: hidden;
    box-shadow:rem( -5px 10px 30px cl(black, .14));
    font-size: rem(16px);
    font-family: $fontTitle;
    display: none;
    position: relative;
    z-index: 2;
    @include media-breakpoint-up(sm) {
      display: block;
      box-shadow:rem( -10px 25px 60px cl(black, .34));
    }
    @include media-breakpoint-down(xs) {
      margin-top: 1rem;
    }
    li {
      margin-bottom: 1px;
    }
    a {
      display: block;
      color:cl(primary);
      padding: rem(12px 10px 12px 25px);
      text-decoration: none;
      &:hover,
      &:focus,
      &.current{
        background: cl(primary);
        color:cl(white);
      }
    }
  }
}