.vision {
  position: relative;
  padding: rem(50px 65px 0 160px);
  //overflow: hidden;
  z-index: 3;
  @include media-breakpoint-down(lg) {
    padding: 3rem 2rem;
  }
  &__text {
    width:rem(670px);
    font-size: rem(18px);
    line-height: 1.5;
    @include media-breakpoint-down(xs) {
      width:auto;
    }
    h2 {
      font-family: $fontTitle;
      text-transform: uppercase;
      font-size: rem(40px);
      color:cl(primary);
      margin: rem(0 0 16px);
    }
  }
  &__list {
    display: flex;
    align-items: flex-start;
    justify-content: flex-end;
    position: relative;
    @include media-breakpoint-up(xl) {
      margin: rem(0 0 -100px);
    }
    @include media-breakpoint-down(xs) {
      //flex-direction: column;
      justify-content: center;
      //align-items: center;
      margin-bottom: 2rem;
    }
    &_center {
      justify-content: center;
      margin: 0;
    }
  }
  &__item {
    width:rem(480px);
    height: rem(480px);
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    color:cl(primary);
    text-transform: uppercase;
    position: relative;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    transition: all .2s linear;
    z-index: 3;
    @include media-breakpoint-down(lg) {
      width: 20rem;
      height: 20rem;
    }
    @include media-breakpoint-down(xs) {
      width: 10rem;
      height: 10rem;
    }
    &:hover {
      box-shadow:rem(-16px 0 0 cl(secondary) , -10px 25px 60px cl(black, .34));
      &:before,
      strong,
      span {
        opacity: 0;
      }
    }
    * {
      transition: all .2s linear;
    }
    &:nth-child(2) {
      margin: rem(280px -32px 0);
      @include media-breakpoint-down(lg) {
        margin: 13.5rem -3rem 0;
      }
      @include media-breakpoint-down(xs) {
        margin: 8.5rem -3rem 0;
      }
    }
    &:before {
      content: "";
      position: absolute;
      left:-1px;
      top:-1px;
      width:calc(100% + 2px);
      height:calc(100% + 2px);
      z-index: 2;
      border-radius: 50%;
      transition: all .2s linear;
      background: linear-gradient(195deg, rgba(255,255,255,1) 0%,rgba(255,255,255,1) 20%, rgba(255,255,255,0.65) 80%, rgba(255,255,255,0.4) 100%);
    }
    strong {
      font-size: rem(110px);
      font-family: $fontTitle;
      font-weight: normal;
      position: relative;
      z-index: 3;
      @include media-breakpoint-down(lg) {
        font-size: rem(80px);
      }
      @include media-breakpoint-down(xs) {
        font-size: rem(36px);
      }
    }
    span {
      font-size: rem(40px);
      position: relative;
      z-index: 3;
      @include media-breakpoint-down(lg) {
        font-size: rem(30px);
      }
      @include media-breakpoint-down(xs) {
        font-size: rem(20px);
      }
    }
  }
  &__ball {
    position: absolute;
    z-index: 1;
    border-radius: 50%;
    &--secondary {
      top: 10%;
      right: 32%;
      width:rem(654px);
      height: rem(654px);
      background: linear-gradient(210deg, rgba(110,169,155,0.65) 0%,rgba(110,169,155,0) 50%);
      opacity: .3;
      @include media-breakpoint-down(lg) {
        width: 30rem;
        height: 30rem;
      }
      @include media-breakpoint-down(xs) {
        display: none;
      }
    }
    &--primary {
      top: 20%;
      right: 15%;
      width:rem(480px);
      height: rem(480px);
      background: linear-gradient(135deg, rgba(28,63,149,0.65) 0%,rgba(28,63,149,0.0) 50%);
      opacity: .55;
      @include media-breakpoint-down(lg) {
        width: 20rem;
        height: 20rem;
      }
      @include media-breakpoint-down(xs) {
        display: none;
      }
    }
  }
  &__leaf {
    position: absolute;
    width:rem(140px);
    height: auto;
    transform: rotate(-70deg) scaleX(-1);
    top: 10%;
    right: 35%;
    z-index: 2;
    @include media-breakpoint-down(xs) {
      top: 54%;
      right: 0;
      width: 4.75rem;
    }
  }
  .decor {
    bottom: rem(130px);
    left:rem(66px);
    @include media-breakpoint-down(xs) {
      bottom: 19.125rem;
      left: 1.125rem;
      opacity: .4;
    }
  }
}