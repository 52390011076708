.find {
  max-width: rem(600px);
  padding: rem(60px 0 90px);
  margin: 0 auto;
  &__inner {
    display: flex;
    @include media-breakpoint-down(xs) {
      flex-wrap: wrap;
      gap:1rem;
    }
  }
  &__txt {
    background: #eee;
    height: rem(50px);
    padding: rem(0 24px);
    display: block;
    width:100%;
    font-family: $font;
    border-radius: rem(25px 0 0 25px);
    border:rem(1px solid #eee);
    font-size:rem(18px);
    color:cl(primary);
    @include placeholder(#9a9a9a);
    @include media-breakpoint-down(xs) {
      border-radius: rem(25px);
    }
    &:focus {
      outline: none;
      box-shadow:none;
      background: #f4f4f4;
      border-color:cl(primary);
    }
  }
  &__btn {
    text-transform: uppercase;
    flex-shrink: 0;
    background: cl(primary);
    color:cl(white);
    font-size: rem(18px);
    line-height: rem(48px);
    font-family: $font;
    font-weight: 700;
    border: rem(1px solid cl(primary));
    cursor: pointer;
    border-radius: rem(0 25px 25px 0);
    padding: rem(0 35px);
    letter-spacing: rem(1px);
    outline: 0;
    white-space: nowrap;
    &:hover,
    &:focus{
      border-color:cl(primary);
      background: cl(primary, .9);
      color:cl(white);
      text-decoration: none;
    }
    @include media-breakpoint-down(xs) {
      width:100%;
      border-radius: rem(25px);
    }
  }
}