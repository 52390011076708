.brands {
    position: relative;
    padding: rem(70px 0 60px);
    background: url(../img/demo/banner-2.jpg) no-repeat 50% 50%;
    background-size: cover;
    @include media-breakpoint-up(lg) {
      background-attachment: fixed;
    }
    &::before {
      content: "";
      @extend .full;
      background: cl(primary, .8);
    }
    h2 {
      text-transform: uppercase;
      font-family: $fontTitle;
      font-size: rem(40px);
      margin: rem(0 0 20px);
      color:cl(white);
    }
    .container {
      position: relative;
    }
    &__leaf {
      position: absolute;
      width: rem(100px);
      height: auto;
      transform: rotate(70deg);
      left: 96%;
      bottom: -20%;
      @include media-breakpoint-down(xs) {
        width: rem(100px);
        right: 2rem;
        left:auto;
        bottom: -5rem;
      }
    }
    &__slider {
      position: relative;
      z-index: 2;
      @include media-breakpoint-down(lg) {
        padding: rem(0 50px);
      }
      @include media-breakpoint-down(xs) {
        padding: rem(0 32px);
      }
      &__prev,
      &__next {
        @extend .pos-centerY;
        left:rem(-80px);
        width:rem(50px);
        height: rem(50px);
        overflow: hidden;
        border-radius: 50%;
        background: cl(white);
        color:cl(secondary);
        border:0;
        font-size: rem(24px);
        z-index: 200;
        &:hover,
        &:focus {
          background: cl(secondary);
          color:cl(white);
        }
        @include media-breakpoint-down(lg) {
          left:0;
        }
        @include media-breakpoint-down(xs) {
          width:rem(32px);
          height: rem(32px);
          font-size: rem(16px);
        }
      }
      &__next {
        left:auto;
        right:rem(-80px);
        @include media-breakpoint-down(lg) {
          right:0;
        }
      }
    }
    &__item {
      display: flex;
      justify-content: center;
      align-items: center;
      height: rem(130px);
      padding: rem(20px);
      margin: rem(15px);
      position: relative;
      background: cl(white);
      border-radius: rem(15px);
      box-shadow:rem(0 0 15px cl(black, .16));
      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
        transition: transform .3s ease;
      }
    }
    a.brands__item {
      &:hover,
      &:focus {
        img {
          transform: scale(1.1);
        }
      }
    }
  }