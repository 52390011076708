.modal-example {
  .modal-dialog {
    max-width: rem(600px);
  }
  .modal-content {
    border: 0;
    border-radius: 0;
    position: relative;
    padding: 0;
  }
  .close {
    margin: 0;
    position: absolute;
    top: rem(20px);
    right: rem(20px);
    width: rem(18px);
    height: rem(18px);
    overflow: hidden;
    text-indent: -999px;
    background: url(../img/close.png) no-repeat 50% 50%;
    background-size: contain;
    padding: 0;
    opacity: 1;
    z-index: 10;
  }
}

.modal-video {
  .modal-dialog {
    max-width: rem(850px);
  }
  video{
    display: block;
    width:100%;
    height: auto;
  }
  .modal-iframe {
    position: relative;
    padding-bottom: 56.25%;
    height: 0;
    iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }
  .modal-content {
    border: 0;
    border-radius: 0;
    position: relative;
    padding:0;
  }
  .close {
    margin: 0;
    position: absolute;
    top: rem(-20px);
    right: rem(-20px);
    width: rem(18px);
    height: rem(18px);
    overflow: hidden;
    text-indent: -999px;
    background: url(../img/close-white.png) no-repeat 50% 50%;
    background-size: contain;
    padding: 0;
    opacity: 1;
    z-index: 10;
    @include media-breakpoint-down(sm) {
      top: rem(-25px);
      right:0;
    }
  }
}