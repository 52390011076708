.files {
  list-style: none;
  padding: 0!important;
  &__item {
    margin: rem(0 0 9px);
    padding: 0!important;
    &:before {
      display: none;
    }
  }
  &__link {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #f5f5f5;
    border-radius: rem(3px);
    padding: rem(13px 20px);
    color:cl(text);
    font-size: rem(16px);
    font: $fontTitle;
    transition: all .2s linear;
    &:hover {
      text-decoration: none;
      color:cl(primary);
      background: cl(white);
      box-shadow: rem(0 3px 13px cl(black, .17));
    }
    i {
      font-size: rem(22px);
    }
  }
}