.page {
  padding: rem(60px 0 50px);
  position: relative;
  &__article {
    font-size: rem(18px);
    line-height:1.5;
    color:cl(text);
    z-index: 2;
    position: relative;
    p {
      margin: rem(0 0 20px);
    }
    h2 {
      font-size: rem(26px);
      margin: rem(0 0 20px);
      font-family: $fontTitle;
      color:cl(primary);
      font-weight: normal;
    }
    h3 {
      font-size: rem(24px);
      margin: rem(0 0 20px);
      font-family: $fontTitle;
      color:cl(primary);
      font-weight: normal;
    }
    h4 {
      font-size: rem(22px);
      margin: rem(0 0 20px);
      font-family: $fontTitle;
      color:cl(primary);
      font-weight: normal;
    }
    h5 {
      font-size: rem(20px);
      margin: rem(0 0 20px);
      font-family: $fontTitle;
      color:cl(primary);
      font-weight: normal;
    }
    h6 {
      font-size: rem(18px);
      margin: rem(0 0 20px);
      font-family: $fontTitle;
      color:cl(primary);
      font-weight: normal;
    }
    ol, ul {
      margin: rem(0 0 20px);
      padding: rem(0 0 0 20px);
    }
    img {
      display: block;
      margin: 0 auto 2rem;
      max-width:90%;
      height: auto;
    }
  }
  &__bottom {
    height: rem(580px);
    background-position: 50% 50%;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    z-index: 1;
    margin-top: rem(-50px);
    @include media-breakpoint-up(lg) {
      background-attachment: fixed;
    }
    @include media-breakpoint-down(xs) {
      height: 10rem;
    }
  }
  &__wave {
    position: absolute;
    width:100%;
    &--top {
      top: rem(-2px);
    }
    &--bottom {
      bottom: rem(-2px);
    }
    img {
      width:100%;
      height: auto;
    }
  }
  & > .decor {
    top: rem(30px);
    @include media-breakpoint-up(sm) {
      left:rem(66px);
    }
    @include media-breakpoint-down(xs) {
      right:rem(50px);
      opacity: .4;
    }
  }
  
  &__wrap {
    display: flex;
    align-items: flex-start;
    gap:rem(50px);
    padding-bottom: rem(70px);
    @include media-breakpoint-down(sm) {
      flex-direction: column;
      align-items: center;
    }
    .sidebar {
      padding: 0;
      margin: 0;
      flex-shrink: 0;
      width:rem(300px);
    }
  }
  
  &__back {
    display: flex;
    justify-content: space-between;
    gap:1rem;
    padding: rem(20px 0 0);
    @include media-breakpoint-down(xs) {
      flex-direction: column-reverse;
    }
  }
}