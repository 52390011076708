.strategic {
  padding-bottom: rem(10px);
  &__item {
    margin-bottom: rem(12px);
    font-size: rem(18px);
    line-height:1.5;
    color:cl(text);
    position: relative;
    z-index: 2;
  }
  &__opener {
    display: flex;
    width:100%;
    text-align: left;
    position: relative;
    justify-content: space-between;
    align-items: center;
    border:0;
    padding: rem(25px 15px 25px 74px);
    box-shadow:rem( -5px 3px 16px cl(black, .09));
    border-radius: rem(15px 0);
    color:#525252;
    background: cl(white);
    &--active {
      .strategic__button {
        transform: rotate(90deg);
      }
    }
    &:hover {
      .strategic__button {
        color:cl(primary);
      }
    }
    h2 {
      margin: 0!important;
      text-transform: uppercase;
    }
  }
  &__num {
    position: absolute;
    left:rem(24px);
    top:rem(28px);
    width:rem(36px);
    height: rem(36px);
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    color:cl(white);
    font-size: rem(26px);
    font-family: $fontTitle;
    background: cl(secondary);
  }
  &__content {
    padding: rem(20px 15px 20px 74px);
    display: none;
    @include media-breakpoint-down(xs) {
      padding-left: rem(15px);
    }
  }
  &__button {
    width:rem(45px);
    height: rem(45px);
    border-radius: 50%;
    color:cl(secondary);
    background: none;
    border:0;
    font-size: rem(28px);
    transition: all .2s linear;
    flex-shrink: 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  h2 {
    font-size: rem(30px);
    margin: rem(0 0 20px);
    font-family: $fontTitle;
    color:cl(primary);
    font-weight: normal;
  }
  h3 {
    font-size: rem(24px);
    margin: rem(0 0 20px);
    font-family: $fontTitle;
    color:cl(primary);
    font-weight: normal;
  }
  h4 {
    font-size: rem(22px);
    margin: rem(0 0 20px);
    font-family: $fontTitle;
    color:cl(primary);
    font-weight: normal;
  }
  h5 {
    font-size: rem(20px);
    margin: rem(0 0 20px);
    font-family: $fontTitle;
    color:cl(primary);
    font-weight: normal;
  }
  h6 {
    font-size: rem(18px);
    margin: rem(0 0 20px);
    font-family: $fontTitle;
    color:cl(primary);
    font-weight: normal;
  }
  ol, ul {
    margin: rem(0 0 20px);
    padding: rem(0 0 0 20px);
  }
}