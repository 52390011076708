/*! Generated by Font Squirrel (https://www.fontsquirrel.com) on June 15, 2022 */



@font-face {
  font-family: 'libre_baskervilleregular';
  src: url('../fonts/librebaskerville-regular.eot');
  src: url('../fonts/librebaskerville-regular.eot?#iefix') format('embedded-opentype'),
  url('../fonts/librebaskerville-regular.woff2') format('woff2'),
  url('../fonts/librebaskerville-regular.woff') format('woff'),
  url('../fonts/librebaskerville-regular.ttf') format('truetype'),
  url('../fonts/librebaskerville-regular.svg#libre_baskervilleregular') format('svg');
  font-weight: normal;
  font-style: normal;

}