.results {
    padding: rem(30px 0);
    position: relative;
    .decor {
        right:5%;
        bottom: 10%;
        @include media-breakpoint-down(lg){
            opacity: .5;
        }
    }
    h2 {
        text-transform: uppercase;
        font-family: $fontTitle;
        font-size: rem(40px);
        margin: rem(0 0 20px);
        color:cl(primary);
      }
    &__reward {
      overflow: hidden;
      margin: rem(0 0 35px);
    }
    &__img {
      width:rem(150px);
      margin: 0 auto;
      position: relative;
      &:before,
      &:after {
        content: "";
        position: absolute;
        top:rem(73px);
        height: 2px;
        background: cl(primary);
        width:rem(1000px);
      }
      &:before {
        right:calc(100% + 2rem);
      }
      &:after {
        left:calc(100% + 2rem);
      }
      img {
        width:100%;
        height: auto;
      }
    }
    &__list {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        gap:2rem;
      padding: rem(30px 0 0);
      @include media-breakpoint-down(xs) {
        margin:0;
      }
      h3 {
        font-size: rem(20px);
        line-height: rem(28px);
        color:cl(primary);
        font-family: $fontTitle;
        margin: rem(0 0 25px);
      }
      ul {
        list-style: none;
        li {
          margin: rem(0 0 25px);
          padding: rem(0 0 0 20px);
          position: relative;
          font-size: rem(16px);
          line-height: 1.4;
          &:before {
            content: "";
            position: absolute;
            left:0;
            top:rem(8px);
            width:rem(5px);
            height: rem(5px);
            border-radius: 50%;
            background: cl(primary);
          }
        }
      }
    } 
    &__item {
        width:calc((100% - 2rem) / 2);
        @include media-breakpoint-down(xs) {
          width:100%;
          margin: 0;
        }
      }
  }