.header {
	position: fixed;
	left:0;
	top:0;
	width:100%;
	z-index: 1000;
	display: flex;
	justify-content: space-between;
	align-items: flex-start;
	padding: rem(15px 25px);
	transition: all .2s linear;
	background: linear-gradient(to bottom,  rgba(0,0,0,0.65) 0%,rgba(0,0,0,0) 100%);
	@include media-breakpoint-down(lg) {
		align-items: center;
		padding: rem(3px 25px);
	}
	&--colored {
		background: cl(white);
		box-shadow: rem(0 3px 5px cl(black, .2));
		padding: rem(3px 25px);
		align-items: center;
		.header__logo--main {
			width:rem(90px);
		}
		.header__buttons {
			.btn--white {
				background: cl(primary);
				color:cl(white);
				border-color:cl(primary);
				&:hover,
				&:focus{
					border-color:cl(primary);
					background: cl(primary, 0.9);
					color:cl(white);
				}
			}
			.btn--white-outline {
				border-color:cl(primary);
				color:cl(primary);
				&:hover,
				&:focus{
					background: cl(primary, 0.1);
					border-color:cl(primary);
					color:cl(primary);
				}
			}
		}
		.header__open-nav {
			color:cl(primary);
			&--active {
				color:cl(white);
			}
		}
		.goog-te-gadget-simple {
			.goog-te-menu-value {
				color:cl(primary)!important;
				span {
					color:cl(primary)!important;
				}
			}
		}
		.header__nav {
			& > ul {
				& > li {
					& > a {
						@include media-breakpoint-up(xl) {
							color:cl(primary);
						}
						&:hover {
							@include media-breakpoint-up(xl) {
								color:cl(secondary);
							}
						}
					}
					&.active {
						& > a {
							@include media-breakpoint-up(xl) {
								color:cl(secondary);
							}
						}
					}
				}
			}
		}
	}
	&__logo {
		flex-shrink: 0;
		transition: all .2s linear;
		img {
			max-width: 100%;
			height: auto;
		}
		&--main {
			width:rem(184px);
			@include media-breakpoint-down(lg) {
				width:rem(90px);
			}
			@include media-breakpoint-down(sm) {
				//width:rem(135px);
			}
		}
		&--nav {
			display: none;
			font-size: rem(20px);
			color:cl(white);
			font-family: $fontTitle;
			text-transform: uppercase;
			position: absolute;
			left:1rem;
			top:2.1rem;
			@include media-breakpoint-down(lg) {
				display: block;
			}
		}
	}
	&__inner {
		display: flex;
		align-items: flex-end;
		flex-direction: column;
		gap:rem(15px);
		width:100%;
		@include media-breakpoint-down(lg) {
			justify-content: flex-end;
			flex-direction: row;
			align-items: center;
			//padding: 1rem 0 0 0;
		}
	}
	&__nav {
		width:100%;
		position: relative;
		display: flex;
		justify-content: flex-end;
		align-items: center;
		z-index: 2;
		@include media-breakpoint-down(lg) {
			position: fixed;
			right:-100%;
			top:0;
			height: 100%;
			margin: 0;
			width:30rem;
			padding: 5rem 0 0;
			background: cl(primary);
			display: flex;
			justify-content: flex-start;
			flex-direction: column;
			transition: all 0.3s linear;
			&.show {
				right:0;
				box-shadow: rem(5px 0 10px rgba(0,0,0,0.3));
			}
		}
		@include media-breakpoint-down(sm) {
			width:100%;
		}
		& > ul {
			display: flex;
			align-items: center;
			gap: rem(30px);
			list-style: none;
			margin: 0;
			@include media-breakpoint-down(lg) {
				height: 100%;
				width:100%;
				overflow: auto;
				display: block;
			}
			& > li {
				position: relative;
				@include media-breakpoint-down(lg) {
					padding: 0;
					margin: 0;
					width:100%;
					border-top:rem(1px solid cl(white, 0.1));
					&:last-child {
						border-bottom:rem(1px solid cl(white, 0.1));
					}
				}
				& > a {
					display: block;
					font-size: rem(18px);
					line-height: rem(24px);
					font-weight: 700;
					color:cl(white);
					text-transform: uppercase;
					border-radius: rem(2px);
					transition: all .2s linear;
					@include media-breakpoint-down(lg) {
						display: block;
						padding: 1.5rem 2rem;
						text-align: center;
					}
					&:hover {
						text-decoration: none;
						color:cl(secondary);
					}
					&.with-drop:after {
						@include media-breakpoint-down(lg) {
							content: '\f107';
							position: absolute;
							top:1.5rem;
							right: 1rem;
							font-family: 'Font Awesome 5 Free';
							font-weight: 900;
							transition: all .2s linear;
						}
					}
					&.with-drop.opened {
						background: #173376;
						&:after {
							transform: rotate(180deg);
						}
					}
				}
				&.active {
					& > a {
						text-decoration: none;
						color:cl(secondary);
					}
				}
				&:hover {
					.drop {
						@include media-breakpoint-up(xl) {
							display: block;
						}
					}
				}
				.drop {
					position: absolute;
					top:rem(17px);
					padding-top: rem(11px);
					left:0;
					width:rem(250px);
					display: none;
					@include media-breakpoint-down(lg) {
						position: static;
						width:100%;
						padding: 0;
						margin: 0;
					}
					ul {
						margin: 0;
						background: cl(secondary);
						border-radius: rem(2px);
						padding: rem(2px);
						list-style: none;
						box-shadow:rem(1px 1px 6px cl(black, .21));
						@include media-breakpoint-down(lg) {
							box-shadow: none;
							padding: 0;
							background: #1a3983;
						}
						li {
							&:not(:last-child) {
								@include media-breakpoint-up(xl) {
									margin-bottom: 1px;
								}
							}
							a {
								display: block;
								font-size: rem(15px);
								line-height: rem(18px);
								color:cl(white);
								border-radius: rem(2px);
								padding: rem(10px 10px 10px 20px);
								@include media-breakpoint-down(lg) {
									display: block;
									padding: 1.5rem 2rem;
									text-align: center;
									border-top:rem(1px solid cl(white, .1));
								}
								&:hover {
									text-decoration: none;
									background: cl(primary);
								}
							}
							&.active {
								a {
									text-decoration: none;
									background: cl(primary);
								}
							}
						}
					}
				}
			}
		}
	}
	&__bottom {
		@include media-breakpoint-down(xs) {
			padding: 1rem;
			width:100%;
			display: flex;
			justify-content: space-between;
		}
		.btn {
			@include media-breakpoint-down(xs) {
				width:48%;
			}
		}
	}
	&__buttons {
		position: relative;
		display: flex;
		gap:rem(26px);
		align-items: center;
		.btn {
			line-height: rem(42px);
		}
		@include media-breakpoint-down(xs) {
			gap:rem(16px);
		}
	}
	&__open-nav {
		display: none;
		position: relative;
		padding: 0;
		height:rem(20px);
		width:rem(26px);
		flex-shrink: 0;
		text-indent: -9999px;
		appearance: none;
		border: none;
		cursor: pointer;
		background: none;
		border:0;
		z-index: 2;
		margin-left: 2rem;
		color: cl(white);
		&:focus {
			outline: none;
		}
		@include media-breakpoint-down(lg){
			display: block;
		}
		@include media-breakpoint-down(xs) {
			margin-left: 1rem;
		}
		span,
		span::before,
		span::after {
			position: absolute;
			display: block;
			left: 0;
			width: 100%;
			height:rem(2px);
			background-color: currentColor;
			content: "";
			transition: transform 0.3s linear;
		}
		span {
			top:rem(9px);
			&:before {
				top:rem(-7px);
			}
			&:after{
				bottom:rem(-7px);
			}
		}
		&--active {
			&:before {
				display: none;
			}
			span {
				background: none;
				&:before {
					top: 0;
					transform:rotate(45deg);
				}
				&:after{
					bottom: 0;
					transform:rotate(-45deg);
				}
			}
		}
	}
}

.translated-ltr,
.translated-rtl {
	.header {
		margin-top: rem(39px);
		.header-inner {
			padding-top: 2.875rem;
		}
		.header-close_nav {
			top: 3.75rem;
		}
	}
}
.goog-te-gadget-simple {
	background: transparent!important;
	border:0!important;
	.goog-te-menu-value {
		color:cl(white)!important;
		font-family: $font;
		span {
			color:cl(white)!important;
		}
	}
	.goog-te-gadget-icon {
		display: none!important;
	}
}