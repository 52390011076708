.news {
  position: relative;
  z-index: 1;
  overflow: hidden;
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: cover;
  @include media-breakpoint-up(lg) {
    background-attachment: fixed;
  }
  &:before {
    content: "";
    @extend .full;
    background: cl(black, .1);
    @include media-breakpoint-down(xs) {
      background: cl(black, .2);
    }
  }
  &__list {
    display: flex;
    flex-wrap: wrap;
    --gap: 2rem;
    gap:var(--gap);
    .news__item {
      --cols: 3;
      flex-basis: calc((100% - var(--gap) * (var(--cols) - 1)) / var(--cols));
      @include media-breakpoint-down(xs) {
        --cols: 1;
      }
    }
  }
  &__wrap {
    padding: rem(50px 0 210px 155px);
    position: relative;
    z-index: 2;
    @include media-breakpoint-down(md) {
      padding: 3rem 0 6rem;
    }
    @include media-breakpoint-down(xs) {
      padding: 7rem 0 6rem;
    }
  }
  &__inner {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    gap:rem(10px);
    @include media-breakpoint-down(md) {
      flex-direction: column;
      align-items: center;
    }
  }
  &__text {
    position: relative;
    z-index: 2;
    width:100%;
    flex-shrink: 0;
    @include media-breakpoint-up(lg) {
      width:rem(265px);
    }
    @include media-breakpoint-down(md) {
      text-align: center;
    }
    h2 {
      text-transform: uppercase;
      font-family: $fontTitle;
      font-size: rem(40px);
      color:cl(primary);
      margin: rem(0 0 40px);
    }
  }
  &__side {
    width:100%;
    margin: rem(80px 0 0);
    @include media-breakpoint-down(xs) {
      margin: 3rem 0 0;
    }
  }
  &__all {
    position: relative;
    z-index: 200;
    margin: rem(-20px 0 20px);
  }
  &__slider {
    .news__item {
      margin: rem(30px 20px 50px 20px);
      width:rem(360px);
      @include media-breakpoint-down(xs) {
        margin: rem(30px 10px 50px 10px);
      }
    }
  }
  &__buttons {
    display: flex;
    gap:rem(13px);
    @include media-breakpoint-down(md) {
      justify-content: center;
    }
  }
  &__button {
    @include circle(50px);
    background: cl(secondary);
    border:0;
    color:cl(white);
    font-size: rem(30px);
    transition: all .2s linear;
    &:hover {
      background: cl(primary);
    }
  }
  &__item {
    border-radius: rem(20px 0);
    overflow: hidden;
    background: cl(white);
    box-shadow:rem(0 5px 20px cl(black, .24));
  }
  &__image {
    height: rem(215px);
    display: block;
    position: relative;
    overflow: hidden;
    &:hover {
      img {
        transform: scale(1.1);
      }
    }
    &:before {
      content: "";
      position: absolute;
      left:-1px;
      bottom: -1px;
      width:calc(100% + 2px);
      height: rem(39px);
      background: url(../img/wave-bottom-news.png) no-repeat 50% 100%;
      background-size: 100% 100%;
      z-index: 2;
    }
    img {
      display: block;
      width:100%;
      height: 100%;
      object-fit: cover;
      transition: all .2s linear;
    }
  }
  &__hold {
    padding: rem(5px 35px 30px);
    font-size: rem(16px);
    line-height: rem(26px);
    h3 {
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 3;
      overflow: hidden;
      font-size: rem(20px);
      line-height: rem(28px);
      height: rem(84px);
      color:cl(primary);
      font-family: $fontTitle;
      a {
        color:cl(primary);
        transition: all .2s linear;
        &:hover {
          text-decoration: none;
          color:cl(secondary);
        }
      }
    }
    p {
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 3;
      overflow: hidden;
      margin: rem(0 0 15px);
      height: rem(78px);
    }
  }
  &__more {
    transition: all .2s linear;
    &:hover {
      text-decoration: none;
      color:cl(secondary);
    }
  }
  &__banner {
    border-radius: rem(45px 0);
    box-shadow:rem(-10px 25px 60px cl(black, .34));
    width:rem(390px);
    overflow: hidden;
    display: block;
    @include media-breakpoint-down(md) {
      margin: 0 auto;
    }
    img {
      width:100%;
      height: auto;
    }
  }
  &__space {
    @include media-breakpoint-down(md) {
      height: 5rem;
      background: white;
    }
    @include media-breakpoint-down(xs) {
      height: 14rem;
    }
  }
  &__wave {
    position: absolute;
    width:100%;
    &--top {
      top: rem(-5px);
    }
    &--bottom {
      bottom: rem(-5px);
    }
    img {
      width:100%;
      height: auto;
    }
  }

  &__waves {
    position:absolute;
    left:0;
    bottom: 0;
    width: 100%;
    height:rem(100px);
    margin-bottom:rem(-1px); /*Fix for safari gap*/
    z-index: 2;
    @include media-breakpoint-down(xs) {
      height:rem(80px);
      //margin-bottom:rem(-3px);
    }
    svg,
    img{
      display: block;
      width:100%;
      height: 100%;
    }
  }
  &__leaf {
    position: absolute;
    width:rem(110px);
    height: auto;
    transform: rotate(70deg);
    bottom: 0;
    right:rem(65px);
    z-index: 2;
    @include media-breakpoint-down(xs) {
      width:5rem;
    }
  }
  .decor {
    top: rem(7px);
    left:rem(66px);
  }
}