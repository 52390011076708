.footer {
  background: #f5f5f5;
  padding: rem(30px 66px);
  color:cl(text);
  @include media-breakpoint-down(lg) {
    padding: rem(30px 32px);
  }
  @include media-breakpoint-down(xs) {
    text-align: center;
  }
  &__social {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    @include media-breakpoint-down(lg) {
      justify-content: center;
      margin-bottom: 2rem;
    }
    a {
      display: flex;
      justify-content: center;
      align-items: center;
      @include circle(42px);
      color:cl(primary);
      margin-left: rem(10px);
      border:rem(1px solid cl(primary));
      transition: all .2s linear;
      &:hover {
        text-decoration: none;
        color:cl(white);
        background-color:cl(primary);
      }
      i {
        font-size: rem(18px);
      }
    }
  }
  &__brand {
    display: flex;
    align-items: center;
    gap:rem(35px);
    @include media-breakpoint-down(lg) {
      justify-content: center;
      margin-bottom: rem(20px);
    }
  }
  &__slogan {
    display: block;
    font-size: rem(40px);
    color:cl(primary);
    font-family: $fontTitle;
    text-transform: uppercase;
    @include media-breakpoint-down(lg) {
      font-size: rem(30px);
    }
    @include media-breakpoint-down(xs) {
      font-size: rem(26px);
    }
  }
  &__logo {
    display: block;
    width:rem(150px);
    img {
      width:100%;
      height: auto;
    }
  }
  &__menu {
    list-style: none;
    @include media-breakpoint-down(xs) {
      margin-bottom: 2rem;
    }
    li {
      padding: rem(5px 0);
    }
    a {
      color:cl(text);
    }
  }
  &__copy {
    display: block;
    font-size: rem(14px);
  }
}

