.history {
  position: relative;
  z-index: 2;
  //overflow: hidden;
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: cover;
  @include media-breakpoint-up(lg) {
    background-attachment: fixed;
  }
  &__inner {
    padding: rem(250px 0 55px);
    display: flex;
    align-items: flex-start;
    justify-content: center;
    position: relative;
    z-index: 3;
    @include media-breakpoint-down(md) {
      flex-direction: column;
      align-items: center;
      padding: 8rem 1rem 4rem;
    }
    @include media-breakpoint-down(xs) {
      padding: 3rem 1rem 4rem;
    }
  }
  &__text {
    position: relative;
    background: cl(white);
    box-shadow:rem(-10px 25px 60px cl(black, .34));
    border-radius: rem(45px 0);
    padding: rem(80px 270px 85px 100px);
    max-width:rem(760px);
    font-size: rem(18px);
    line-height: 1.5;
    margin: rem(35px 0 0);
    @include media-breakpoint-down(xs) {
      padding: 5rem 2rem 2rem;
    }
    h2 {
      position: relative;
      z-index: 2;
      text-transform: uppercase;
      font-family: $fontTitle;
      font-size: rem(40px);
      color:cl(primary);
      margin: rem(0 0 40px);
    }
  }
  &__info {
    width:rem(620px);
    position: relative;
    z-index: 2;
    @include media-breakpoint-up(lg) {
      margin: rem(0 0 0 -191px);
    }
    @include media-breakpoint-down(xs) {
      width:100%;
      margin-top: 2rem;
    }
    &:before {
      content: "";
      position: absolute;
      bottom: 0;
      right:0;
      width:rem(500px);
      height: rem(400px);
      z-index: 1;
      background: radial-gradient(ellipse at center,  rgba(255,255,255,1) 0%, rgba(255,255,255,1) 30%, rgba(255,255,255,0) 75%);
    }
  }
  &__image {
    height: rem(350px);
    border-radius: rem(45px 0);
    overflow: hidden;
    box-shadow:rem(8px -8px 0 cl(primary), -10px 25px 60px cl(black, .34));
    margin: rem(0 0 35px);
    position: relative;
    z-index: 2;
    @include media-breakpoint-down(xs) {
      height: 14rem;
    }
    img {
      display: block;
      width:100%;
      height: 100%;
      object-fit: cover;
    }
  }
  &__play {
    @extend .pos-center;
    color:cl(white);
    opacity: .5;
    transition: all .2s linear;
    font-size: rem(80px);
    &:hover {
      color:cl(white);
      opacity: .8;
    }
  }
  &__ttl,
  &__txt {
    text-align: right;
    text-transform: uppercase;
    color:cl(primary);
    font-family: $fontTitle;
    letter-spacing: rem(5px);
    position: relative;
    z-index: 2;
    white-space: nowrap;
  }
  &__ttl {
    font-size: rem(50px);
    @include media-breakpoint-down(xs) {
      font-size: rem(30px);
    }
  }
  &__txt {
    font-size: rem(110px);
    @include media-breakpoint-down(xs) {
      font-size: rem(70px);
    }
    span {
      position: relative;
    }
  }
  &__leaf {
    position: absolute;
    width:rem(75px);
    height: auto;
    top: -3%;
    right: -33%;
    z-index: 2;
    @include media-breakpoint-down(xs) {
      width: 2.6875rem;
    }
  }
  &__wave {
    position: absolute;
    width:100%;
    &--top {
      top: rem(-2px);
    }
    &--bottom {
      bottom: rem(-2px);
    }
    img {
      width:100%;
      height: auto;
    }
  }
  .decor {
    top: rem(25px);
    @include media-breakpoint-up(sm) {
      left:rem(50px);
    }
    @include media-breakpoint-down(xs) {
      right:rem(50px);
    }
  }
}